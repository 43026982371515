import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Itzulia Basque Country",
      edition: 2022,
      what: "Race data",
      updated: "2022-04-01 11:31:02",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00c3\u00abn Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        teamNationCode: "BEL",
      },
      21868: {
        teamId: "21868",
        teamUciCode: "TEN",
        teamName: "TotalEnergies",
        teamNationCode: "FRA",
      },
      22016: {
        teamId: "22016",
        teamUciCode: "EUS",
        teamName: "Euskaltel-Euskadi",
        teamNationCode: "ESP",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      22925: {
        teamId: "22925",
        teamUciCode: "EKP",
        teamName: "Equipo Kern Pharma",
        teamNationCode: "ESP",
      },
      23399: {
        teamId: "23399",
        teamUciCode: "BBH",
        teamName: "Burgos-BH",
        teamNationCode: "ESP",
      },
      23455: {
        teamId: "23455",
        teamUciCode: "CJR",
        teamName: "Caja Rural-Seguros RGA",
        teamNationCode: "ESP",
      },
    },
    riders: {
      70377: {
        id: 70377,
        startno: 227,
        firstName: "Maxim",
        lastName: "Van Gils",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-11-25",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      46341: {
        id: 46341,
        startno: 132,
        firstName: "Jonathan",
        lastName: "Lastra",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-06-03",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      15752: {
        id: 15752,
        startno: 105,
        firstName: "Pierre",
        lastName: "Latour",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-12",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      97996: {
        id: 97996,
        startno: 17,
        firstName: "Edoardo",
        lastName: "Zambanini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-04-21",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      37281: {
        id: 37281,
        startno: 86,
        firstName: "Aleksandr",
        lastName: "Vlasov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-04-23",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      58639: {
        id: 58639,
        startno: 83,
        firstName: "Sergio",
        lastName: "Higuita",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-08-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      6529: {
        id: 6529,
        startno: 174,
        firstName: "Jesus",
        lastName: "Ezquerra",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-11-30",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      39481: {
        id: 39481,
        startno: 146,
        firstName: "Nicolas",
        lastName: "Prodhomme",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-02-01",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      22389: {
        id: 22389,
        startno: 147,
        firstName: "Andrea",
        lastName: "Vendrame",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-07-20",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      9008: {
        id: 9008,
        startno: 186,
        firstName: "Simon",
        lastName: "Pellaud",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-11-06",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      63296: {
        id: 63296,
        startno: 151,
        firstName: "Sebastian",
        lastName: "Berwick",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1999-12-15",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      82254: {
        id: 82254,
        startno: 87,
        firstName: "Frederik",
        lastName: "Wandahl",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2001-05-09",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      37392: {
        id: 37392,
        startno: 196,
        firstName: "Gotzon",
        lastName: "Martin",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-02-15",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      4004: {
        id: 4004,
        startno: 182,
        firstName: "Kenny",
        lastName: "Elissonde",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-07-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37308: {
        id: 37308,
        startno: 2,
        firstName: "Pascal",
        lastName: "Eenkhoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-02-08",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      3165: {
        id: 3165,
        startno: 46,
        firstName: "Pieter",
        lastName: "Serry",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      153042: {
        id: 153042,
        startno: 37,
        firstName: "Javier",
        lastName: "Romo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-01-06",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      46626: {
        id: 46626,
        startno: 53,
        firstName: "Sergio",
        lastName: "Samitier",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-08-31",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      37375: {
        id: 37375,
        startno: 217,
        firstName: "James",
        lastName: "Shaw",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-06-13",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      1615: {
        id: 1615,
        startno: 177,
        firstName: "Jetse",
        lastName: "Bol",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-09-08",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      27332: {
        id: 27332,
        startno: 222,
        firstName: "Steff",
        lastName: "Cras",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-02-13",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      10807: {
        id: 10807,
        startno: 73,
        firstName: "Ruben",
        lastName: "Fernandez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-03-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      17183: {
        id: 17183,
        startno: 101,
        firstName: "J\u00c3\u00a9r\u00c3\u00a9my",
        lastName: "Cabot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-07-24",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      65440: {
        id: 65440,
        startno: 123,
        firstName: "Leon",
        lastName: "Heinschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-11-08",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      3991: {
        id: 3991,
        startno: 25,
        firstName: "Rafal",
        lastName: "Majka",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1989-09-12",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      16569: {
        id: 16569,
        startno: 157,
        firstName: "Michael",
        lastName: "Woods",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1986-10-12",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      95148: {
        id: 95148,
        startno: 137,
        firstName: "Jokin",
        lastName: "Murguialday Elorza",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2000-03-19",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      14712: {
        id: 14712,
        startno: 121,
        firstName: "Romain",
        lastName: "Combaud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-01",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      20616: {
        id: 20616,
        startno: 35,
        firstName: "Antonio",
        lastName: "Nibali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-09-23",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      6538: {
        id: 6538,
        startno: 23,
        firstName: "Jan",
        lastName: "Polanc",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1992-05-06",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      745: {
        id: 745,
        startno: 61,
        firstName: "Andrey",
        lastName: "Amador",
        nationCode: "CRC",
        nationName: "Costa Rica",
        birthDate: "1986-08-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      65759: {
        id: 65759,
        startno: 191,
        firstName: "Xabier",
        lastName: "Azparren",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-11-25",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      352: {
        id: 352,
        startno: 164,
        firstName: "Matthieu",
        lastName: "Ladagnous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1984-12-12",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      45987: {
        id: 45987,
        startno: 21,
        firstName: "Alessandro",
        lastName: "Covi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-09-28",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      3355: {
        id: 3355,
        startno: 92,
        firstName: "Lawson",
        lastName: "Craddock",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-02-20",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      9302: {
        id: 9302,
        startno: 82,
        firstName: "Felix",
        lastName: "Gro\u00c3\u009fschartner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-12-23",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      33263: {
        id: 33263,
        startno: 211,
        firstName: "Jonathan",
        lastName: "Caicedo",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-03-28",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      57357: {
        id: 57357,
        startno: 202,
        firstName: "Jos\u00c3\u00a9 F\u00c3\u00a9lix",
        lastName: "Parra",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-01-16",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      27295: {
        id: 27295,
        startno: 45,
        firstName: "James",
        lastName: "Knox",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-11-04",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      27240: {
        id: 27240,
        startno: 96,
        firstName: "Jan",
        lastName: "Maas",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-19",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      38818: {
        id: 38818,
        startno: 111,
        firstName: "Kobe",
        lastName: "Goossens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-04-29",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      46625: {
        id: 46625,
        startno: 57,
        firstName: "\u00c3\u0093scar",
        lastName: "Rodr\u00c3\u00adguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-05-06",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89666: {
        id: 89666,
        startno: 206,
        firstName: "Ibon",
        lastName: "Ruiz",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-01-30",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      9344: {
        id: 9344,
        startno: 81,
        firstName: "Emanuel",
        lastName: "Buchmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-18",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      23206: {
        id: 23206,
        startno: 156,
        firstName: "Carl Fredrik",
        lastName: "Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1991-09-26",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      77143: {
        id: 77143,
        startno: 226,
        firstName: "Xandres",
        lastName: "Vervloesem",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-05-13",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      868: {
        id: 868,
        startno: 131,
        firstName: "Mikel",
        lastName: "Nieve",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-05-26",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      70767: {
        id: 70767,
        startno: 104,
        firstName: "Alan",
        lastName: "Jousseaume",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-08-03",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      27101: {
        id: 27101,
        startno: 155,
        firstName: "Omer",
        lastName: "Goldstein",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1996-08-13",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      44341: {
        id: 44341,
        startno: 32,
        firstName: "Stefan",
        lastName: "de Bod",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-11-17",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      27124: {
        id: 27124,
        startno: 64,
        firstName: "Daniel",
        lastName: "Martinez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-04-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      46756: {
        id: 46756,
        startno: 173,
        firstName: "Oscar",
        lastName: "Cabedo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-11-12",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      97944: {
        id: 97944,
        startno: 145,
        firstName: "Valentin",
        lastName: "Paret-Peintre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-01-14",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      65515: {
        id: 65515,
        startno: 91,
        firstName: "Kevin",
        lastName: "Colleoni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-11-11",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      6184: {
        id: 6184,
        startno: 166,
        firstName: "Sebastien",
        lastName: "Reichenbach",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1989-05-28",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      16576: {
        id: 16576,
        startno: 97,
        firstName: "Nick",
        lastName: "Schultz",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-09-13",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      69317: {
        id: 69317,
        startno: 16,
        firstName: "Johan",
        lastName: "Price-Pejtersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-05-26",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      18241: {
        id: 18241,
        startno: 195,
        firstName: "Mikel",
        lastName: "Iturria",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-03-16",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      17182: {
        id: 17182,
        startno: 181,
        firstName: "Julien",
        lastName: "Bernard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-03-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37339: {
        id: 37339,
        startno: 34,
        firstName: "Yuriy",
        lastName: "Natarov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1996-12-28",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      84019: {
        id: 84019,
        startno: 44,
        firstName: "Remco",
        lastName: "Evenepoel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-01-25",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      25726: {
        id: 25726,
        startno: 27,
        firstName: "Marc",
        lastName: "Soler",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-11-22",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      104684: {
        id: 104684,
        startno: 192,
        firstName: "Xabier",
        lastName: "Isasa",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-08-24",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      69145: {
        id: 69145,
        startno: 122,
        firstName: "Mark",
        lastName: "Donovan",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-04-03",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      64761: {
        id: 64761,
        startno: 176,
        firstName: "Alex",
        lastName: "Molenaar",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-07-13",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      3095: {
        id: 3095,
        startno: 116,
        firstName: "Kevin",
        lastName: "Van Melsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-04-01",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      2977: {
        id: 2977,
        startno: 71,
        firstName: "Ion",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-02-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      9154: {
        id: 9154,
        startno: 76,
        firstName: "Pierre-Luc",
        lastName: "P\u00c3\u00a9richon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-01-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      38803: {
        id: 38803,
        startno: 117,
        firstName: "Georg",
        lastName: "Zimmermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-11",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      3287: {
        id: 3287,
        startno: 152,
        firstName: "Patrick",
        lastName: "Bevin",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1991-05-02",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      18490: {
        id: 18490,
        startno: 153,
        firstName: "Alexander",
        lastName: "Cataford",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1993-09-01",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      27308: {
        id: 27308,
        startno: 135,
        firstName: "Fernando",
        lastName: "Barcel\u00c3\u00b3",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-01-06",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      94862: {
        id: 94862,
        startno: 115,
        firstName: "Hugo",
        lastName: "Page",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-07-24",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      20511: {
        id: 20511,
        startno: 31,
        firstName: "Valerio",
        lastName: "Conti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      27210: {
        id: 27210,
        startno: 14,
        firstName: "Domen",
        lastName: "Novak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1995-07-24",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      95531: {
        id: 95531,
        startno: 204,
        firstName: "Daniel Alejandro",
        lastName: "Mendez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "2000-06-02",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      54660: {
        id: 54660,
        startno: 185,
        firstName: "Juan Pedro",
        lastName: "Lopez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-07-31",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      22272: {
        id: 22272,
        startno: 162,
        firstName: "Bruno",
        lastName: "Armirail",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-04-11",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      2741: {
        id: 2741,
        startno: 184,
        firstName: "Gianluca",
        lastName: "Brambilla",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      48008: {
        id: 48008,
        startno: 5,
        firstName: "Sepp",
        lastName: "Kuss",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-09-13",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      16578: {
        id: 16578,
        startno: 55,
        firstName: "Gregor",
        lastName: "Muhlberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1994-04-04",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      41295: {
        id: 41295,
        startno: 136,
        firstName: "Jefferson",
        lastName: "Cepeda",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1996-03-02",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      45082: {
        id: 45082,
        startno: 201,
        firstName: "Hector",
        lastName: "Carretero",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-05-28",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      51244: {
        id: 51244,
        startno: 141,
        firstName: "Cl\u00c3\u00a9ment",
        lastName: "Champoussin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-05-29",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      60067: {
        id: 60067,
        startno: 6,
        firstName: "Milan",
        lastName: "Vader",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-18",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      2503: {
        id: 2503,
        startno: 72,
        firstName: "Sander",
        lastName: "Armee",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-12-10",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      1527: {
        id: 1527,
        startno: 54,
        firstName: "Enric",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-01-07",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      37391: {
        id: 37391,
        startno: 205,
        firstName: "Diego",
        lastName: "Lopez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-09-12",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      168: {
        id: 168,
        startno: 74,
        firstName: "Simon",
        lastName: "Geschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-03-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      66973: {
        id: 66973,
        startno: 124,
        firstName: "Andreas",
        lastName: "Leknessund",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-05-21",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      47176: {
        id: 47176,
        startno: 225,
        firstName: "Viktor",
        lastName: "Verschaeve",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-08-03",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      16947: {
        id: 16947,
        startno: 106,
        firstName: "Cristian",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-03-03",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      20836: {
        id: 20836,
        startno: 103,
        firstName: "Fabien",
        lastName: "Grellier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-10-31",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      27283: {
        id: 27283,
        startno: 62,
        firstName: "Edward",
        lastName: "Dunbar",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1996-09-01",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      16992: {
        id: 16992,
        startno: 214,
        firstName: "Ruben",
        lastName: "Guerreiro",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1994-07-06",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      9114: {
        id: 9114,
        startno: 33,
        firstName: "Sebastian",
        lastName: "Henao",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1993-08-05",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      325: {
        id: 325,
        startno: 172,
        firstName: "Daniel",
        lastName: "Navarro",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-07-08",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      27301: {
        id: 27301,
        startno: 144,
        firstName: "Aur\u00c3\u00a9lien",
        lastName: "Paret-Peintre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-02-27",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      14126: {
        id: 14126,
        startno: 77,
        firstName: "Davide",
        lastName: "Villella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-06-27",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      26520: {
        id: 26520,
        startno: 163,
        firstName: "Matteo",
        lastName: "Badilatti",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-07-30",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      37445: {
        id: 37445,
        startno: 94,
        firstName: "Lucas",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-12",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      38195: {
        id: 38195,
        startno: 7,
        firstName: "Jonas",
        lastName: "Vingegaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-12-10",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      7635: {
        id: 7635,
        startno: 194,
        firstName: "Mikel",
        lastName: "Bizkarra",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-08-21",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      353: {
        id: 353,
        startno: 167,
        firstName: "Anthony",
        lastName: "Roux",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-04-18",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      163: {
        id: 163,
        startno: 11,
        firstName: "Yukiya",
        lastName: "Arashiro",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1984-09-22",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      752: {
        id: 752,
        startno: 43,
        firstName: "Dries",
        lastName: "Devenyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1983-07-22",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      4863: {
        id: 4863,
        startno: 12,
        firstName: "Pello",
        lastName: "Bilbao",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-02-25",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      114756: {
        id: 114756,
        startno: 207,
        firstName: "Igor",
        lastName: "Arrieta",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2002-12-08",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      20147: {
        id: 20147,
        startno: 67,
        firstName: "Adam",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      7729: {
        id: 7729,
        startno: 127,
        firstName: "Martijn",
        lastName: "Tusveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-09-09",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      5515: {
        id: 5515,
        startno: 114,
        firstName: "Laurens",
        lastName: "Huys",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-09-24",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      84926: {
        id: 84926,
        startno: 65,
        firstName: "Carlos",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-02-02",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      12474: {
        id: 12474,
        startno: 41,
        firstName: "Julian",
        lastName: "Alaphilippe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-06-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      163759: {
        id: 163759,
        startno: 85,
        firstName: "Anton",
        lastName: "Palzer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-03-11",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      3352: {
        id: 3352,
        startno: 22,
        firstName: "George",
        lastName: "Bennett",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-04-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      16587: {
        id: 16587,
        startno: 36,
        firstName: "Alexandr",
        lastName: "Riabushenko",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1995-10-12",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      25670: {
        id: 25670,
        startno: 133,
        firstName: "Julen",
        lastName: "Amezqueta",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-08-12",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      14716: {
        id: 14716,
        startno: 107,
        firstName: "Alexis",
        lastName: "Vuillermoz",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-06-01",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      11011: {
        id: 11011,
        startno: 24,
        firstName: "Davide",
        lastName: "Formolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-10-25",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      18655: {
        id: 18655,
        startno: 1,
        firstName: "Primoz",
        lastName: "Roglic",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-10-29",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      78384: {
        id: 78384,
        startno: 212,
        firstName: "Diego",
        lastName: "Camargo",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-05-03",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      45113: {
        id: 45113,
        startno: 175,
        firstName: "Adria",
        lastName: "Moreno Sala",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-08-19",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      37378: {
        id: 37378,
        startno: 161,
        firstName: "David",
        lastName: "Gaudu",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-10-10",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      46012: {
        id: 46012,
        startno: 221,
        firstName: "Filippo",
        lastName: "Conca",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-09-22",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      27043: {
        id: 27043,
        startno: 216,
        firstName: "Mark",
        lastName: "Padun",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1996-07-06",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      568: {
        id: 568,
        startno: 154,
        firstName: "Simon",
        lastName: "Clarke",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-07-18",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      1715: {
        id: 1715,
        startno: 95,
        firstName: "Christopher",
        lastName: "Juul-Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1989-07-06",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      37386: {
        id: 37386,
        startno: 197,
        firstName: "Ibai",
        lastName: "Azurmendi",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-06-11",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      918: {
        id: 918,
        startno: 183,
        firstName: "Tony",
        lastName: "Gallopin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-05-24",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      41: {
        id: 41,
        startno: 3,
        firstName: "Robert",
        lastName: "Gesink",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-05-31",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      70034: {
        id: 70034,
        startno: 47,
        firstName: "Mauri",
        lastName: "Vansevenant",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-06-01",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      872: {
        id: 872,
        startno: 52,
        firstName: "Gorka",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-10-07",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      10996: {
        id: 10996,
        startno: 51,
        firstName: "Antonio",
        lastName: "Pedrero",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-10-23",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      7998: {
        id: 7998,
        startno: 223,
        firstName: "Matthew",
        lastName: "Holmes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-12-08",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      22143: {
        id: 22143,
        startno: 187,
        firstName: "Antwan",
        lastName: "Tolhoek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-04-29",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      51348: {
        id: 51348,
        startno: 224,
        firstName: "Sylvain",
        lastName: "Moniquet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-14",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      1323: {
        id: 1323,
        startno: 75,
        firstName: "Jose",
        lastName: "Herrada",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1985-10-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      33063: {
        id: 33063,
        startno: 102,
        firstName: "Fabien",
        lastName: "Doubey",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-21",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      49957: {
        id: 49957,
        startno: 126,
        firstName: "Florian",
        lastName: "Stork",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-04-27",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      1008: {
        id: 1008,
        startno: 56,
        firstName: "Nelson",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1989-03-06",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      73467: {
        id: 73467,
        startno: 15,
        firstName: "Alejandro",
        lastName: "Osorio",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-05-28",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      86651: {
        id: 86651,
        startno: 171,
        firstName: "Ander",
        lastName: "Okamika",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-04-02",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      79947: {
        id: 79947,
        startno: 143,
        firstName: "Paul",
        lastName: "Lapeira",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-05-25",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      6227: {
        id: 6227,
        startno: 42,
        firstName: "Mattia",
        lastName: "Cattaneo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-10-25",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      37259: {
        id: 37259,
        startno: 13,
        firstName: "Gino",
        lastName: "M\u00c3\u00a4der",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-01-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      39009: {
        id: 39009,
        startno: 4,
        firstName: "Chris",
        lastName: "Harper",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-11-23",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      6269: {
        id: 6269,
        startno: 113,
        firstName: "Jan",
        lastName: "Hirt",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1991-01-22",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      107572: {
        id: 107572,
        startno: 193,
        firstName: "Carlos",
        lastName: "Canal",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-06-28",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      6222: {
        id: 6222,
        startno: 165,
        firstName: "Rudy",
        lastName: "Molard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-17",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      794: {
        id: 794,
        startno: 26,
        firstName: "Diego",
        lastName: "Ulissi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-07-15",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      37221: {
        id: 37221,
        startno: 134,
        firstName: "Orluis",
        lastName: "Aular",
        nationCode: "VEN",
        nationName: "Venezuela",
        birthDate: "1996-11-05",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      48547: {
        id: 48547,
        startno: 142,
        firstName: "Felix",
        lastName: "Gall",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1998-02-27",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      18117: {
        id: 18117,
        startno: 215,
        firstName: "Merhawi",
        lastName: "Kudus",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1994-01-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      58361: {
        id: 58361,
        startno: 203,
        firstName: "Jon",
        lastName: "Agirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-09-10",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      716: {
        id: 716,
        startno: 66,
        firstName: "Geraint",
        lastName: "Thomas",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1986-05-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      64651: {
        id: 64651,
        startno: 125,
        firstName: "Tim",
        lastName: "Naberman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-05-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      31066: {
        id: 31066,
        startno: 112,
        firstName: "Quinten",
        lastName: "Hermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-07-29",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      7640: {
        id: 7640,
        startno: 63,
        firstName: "Omar",
        lastName: "Fraile",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-07-17",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      6255: {
        id: 6255,
        startno: 93,
        firstName: "Tsgabu",
        lastName: "Grmay",
        nationCode: "ETH",
        nationName: "Ethiopia",
        birthDate: "1991-08-25",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      27289: {
        id: 27289,
        startno: 84,
        firstName: "Lennard",
        lastName: "K\u00c3\u00a4mna",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1996-09-09",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      17252: {
        id: 17252,
        startno: 213,
        firstName: "Odd Christian",
        lastName: "Eiking",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-28",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Basque"
  const raceID = 6

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
